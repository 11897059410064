.registration {
  .register_showcase {
    height: 27rem;
    background: url(../assets/images/register/pexels-photo-461077-1.png)
      hsla(0, 0%, 100%, 1) 0% 0% no-repeat padding-box !important;
    background-position: center !important;
    background-size: cover !important;
  }
  .regiseter_title {
    background: hsla(207, 81%, 21%, 1);
    -webkit-background-clip: text;
    font-weight: bold;
    // -webkit-text-fill-color: transparent;
    display: inline-block;
  }
  .regiseter_form {
    // height: 800px;
    // min-height: 100vh;
    background: hsla(0, 0%, 100%, 1) 0% 0% no-repeat padding-box;
    border: 1px solid hsla(0, 0%, 43%, 1);
    border-radius: 10px;
    // transform: translateY(-12rem);
    margin-top: -12rem;
    &.to_trans_top {
      // transform: translateY(-15rem);
      margin-top: -15rem;
    }
    margin-bottom: 2rem;
    a {
      color: #0089ef !important;
    }
  }
}

.steper {
  .nav-link {
    display: flex;
    align-items: center;
    // opacity: 0.5;
    padding: 0.2rem 0.2rem;
    .dash {
      background: #000;
      width: 20px;
      height: 3px;
    }
    .active_icon {
      display: none;
    }
    .not_active_icon {
      background: url(../assets/icons/not_actice_icon.svg) no-repeat;
      width: 20px;
      height: 20px;
      background-position: center;
      background-size: cover;
      margin: 0 0.5rem;
      filter: brightness(0.5);
    }
    &.active {
      opacity: 1;
      text-shadow: 1px 0px 1px #040738;

      .active_icon {
        display: inline-block;
        background: url(../assets/icons/active_step_icon.svg) no-repeat !important;
        width: 20px !important;
        height: 20px !important;
        background-position: center !important;
        background-size: cover !important;
        margin: 0 0.5rem !important;
      }
      .not_active_icon {
        display: none;
      }
    }
  }
}

.step-form {
  .regisetr_form_wrapper {
    // padding: 0 6rem;

    label {
      color: #0a3962;
      font-size: 1.2rem;
    }
  }
}
.google_login {
  text-align: center;
  margin-bottom: 1rem;
  border: 1px solid #5088ff;
  background: #5088ff;
  border-radius: 3rem;
  padding: 1rem;
  color: #ffffff;
  outline: none;
  font-size: 1.2rem;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.apple_btn {
  text-align: center;
  margin-bottom: 1rem;
  border: 1px solid #000000;
  background: #000000;
  border-radius: 3rem;
  padding: 1rem 1.4rem;
  color: #ffffff;
  outline: none;
  font-size: 1.2rem;
  &:focus {
    outline: none;
  }
}

.line_hirizental {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ccc;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
.line_hirizental span {
  background: #fff;
  padding: 0 10px;
}

.select_form {
  width: 100%;
  border: 0.1px solid #0a396230;
  border-radius: 10px !important;
  padding: 0.7rem;
  outline: none;
  opacity: 1;
  transition: all 0.2s;
  color: #474747;
  background: #fcfcfc 0% 0% no-repeat padding-box !important;
  &:focus {
    background: white;
    border-radius: 3px;
    background: #ffff !important;
    border: 0.2px solid #0a396230;
  }
}

.register_nav_step {
  @media screen and (max-width: 575px) {
    display: none !important;
  }
}
