html,
body {
  // max-width: 100%;
  // overflow-x: hidden;
}
html {
  height: 100%;
  overflow: auto;
}
body {
  height: 100%;
}
.app {
  .ar {
    *,
    ul li,
    p,
    label,
    .secounry_c,
    input {
      font-family: "Cairo", sans-serif !important;
    }
  }
}
/* scrollbar */
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #0a396294;
  border-radius: 5px;
}

@media screen and (min-width: 768px) {
  .offset-md-2.ar {
    margin-right: 16.666667% !important;
    margin-left: 0px !important;
  }
}

#page-container {
  position: relative;
  min-height: 90vh;
}

a.link_color {
  color: #0089ef !important;
}

.ui.search {
  width: 75%;
  margin: 0 auto;
  .ui.input {
    width: 100%;
    height: 4rem;
    input.prompt {
      height: 48px;
      border-radius: 10px !important;
      border: 1px solid #707070;
      & ~ .search.icon {
        height: 48px;
      }
    }
  }
  .results.transition.visible {
    width: 100%;
  }
}

.login_form_wrapper {
  // height: 40rem;
  min-height: 20rem;
}

.question_answer {
  .title {
    display: flex;
    p {
      font-size: 2rem;
      margin: 10px;
    }
    img {
      width: 3rem;
    }
  }
}
.tags {
  .tag {
    background: transparent;
    outline: none;
    padding: 0 1.7rem;
    border-radius: 2rem;
    transition: all 0.3s;
    line-height: 1;
    min-height: 3rem;
    width: 12rem;
    text-transform: capitalize;
    &.active {
      background: #0a3962;
      color: white;
    }
    &:hover {
      background: #0a3962;
      color: white;
    }
  }
}

.btn_big_round {
  padding: 0.8rem 5rem !important;
  border-radius: 0.6rem !important;
  &.not_primary {
    background: #cccccc !important;
  }
}

.share {
  cursor: pointer;
}
.flag {
  color: #71b5e4;
  cursor: pointer;
}

.text_area_qa {
  width: 100%;

  border-radius: 10px !important;
  padding: 0.7rem;
  outline: none;
  opacity: 1;
  color: #474747;
  background: #ffffff 0% 0% no-repeat padding-box;
  transition: all 0.2s;
  &:focus {
    border: 1px solid blue !important;
    border-radius: 3px;
  }
}

.rate_page {
  background: url("../../assets/images/Group\ 20.png") !important;
  background-position-y: bottom !important;
}

.bg_gray {
  background: #f6f6f6;
}
label {
  font-weight: bold;
}
.form_trasform {
  background: hsla(0, 0%, 100%, 1) 0% 0% no-repeat padding-box;
  border: 1px solid hsla(0, 0%, 43%, 1);
  border-radius: 10px;
  // transform: translateY(-16rem);
  margin-top: -15rem;
  margin-bottom: 2rem;
}
label,
input::placeholder {
  text-transform: capitalize;
}

.ui.active.button {
  background-color: #0a3962;
  color: white;
}

.upload_images {
  padding: 1rem;
  border: solid 2px #c2cdda;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.upload_button {
  background: #0a3962;
  border: none;
  color: white;
}

.form-group.error input {
  // animation-name: inputMove;
  // animation-duration: 0.5s;
}

@keyframes inputMove {
  0% {
    transform: translateX(5px);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0px);
  }
}

.primary_button {
  background: #0089ef;
  padding: 0.5rem 1rem;
  color: #ffffff !important;
  border: none;
  border-radius: 0.5rem;
  text-transform: capitalize;
  text-align: center;
}
.bg_white {
  background: #ffff !important;
}

.ui.primary.button {
  border-radius: 10px;
}

.card {
  height: 100%;
}

.line_with_middle {
  .middle {
    width: 100%;
    text-align: center;

    line-height: 0.1em;
    margin: 10px 0 20px;

    span {
      background: white;
      padding: 0 10px;
    }
  }
}

.work_creativity_circle .CircularProgressbar-trail {
  stroke: #ffff !important ;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: #00a8f2 !important;
}

.round_circle {
  height: 6rem;
  width: 6rem;
  background: #0a3962;
  border-radius: 50%;
  color: #fff;
  // font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal_ar {
  h3,
  p {
    font-family: "Cairo", sans-serif;
  }
}
.app.ar {
  label,
  button,
  .nav-link,
  a,
  input,
  textarea {
    font-family: "Cairo", sans-serif !important;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// input {
//   transition: all 0.2s;
//   &:focus {
//     border: 1px solid blue !important;
//   }
// }

.share_link_copy {
  input {
    width: 150%;
    display: block;
    color: black;
    border: none;
    border-bottom: 1px solid #757575;
    transition: all 0.3s;
    &:focus {
      outline: none;
      border-bottom: 2px solid blue;
    }
  }
}

.company__logo {
  background: white;
  height: 10rem;
  border-radius: 50%;
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.required label:not(.form-check-label):after {
  content: " *";
  color: red;
}

.btn-link {
  border: none;
  background: transparent;
  padding: 0px;
}

.ui.search > .results .result .image {
  display: flex;
  justify-content: center;
  img {
    width: 60px;
  }
}

.react-rater-star.is-active,
.react-rater-star.is-active-half:before {
  color: #ffc850 !important;
}
.mr-02 {
  margin-right: 0.2rem;
}

// @media screen and (max-width: 991px) {
//   .filter_hide_phone {
//     display: none !important;
//   }
// }
.toggle_show_password {
  cursor: pointer;
}

// companys
.company_title____ {
  @media screen and (max-width: 522px) {
    width: auto !important;
  }
}
.companies-feature {
  .bg_white {
    background-color: transparent !important;
  }
  .company_logo {
    background-color: white;
  }
}

.form-group.ar {
  .form-check-inline {
    margin-right: unset;
    margin-left: 0.75rem;
  }
  label {
    margin-right: 10px;
  }
}

.culture_rate.ar {
  .react-rater {
    .react-rater-star.is-active-half:before {
      right: 0;
      left: unset;
    }
  }
}

.swiper-container {
  .react-rater {
    vertical-align: sub;
    margin-right: 10px;
  }
}
.app[dir="rtl"] {
  .swiper-container {
    .react-rater {
      margin-left: 10px;
    }
  }
  .is-active-half::before {
    right: 0 !important;
  }
}

.ui.rating {
  vertical-align: middle;
  align-items: center;
}

.content {
  p {
    font-weight: bold !important;
  }
}

img {
  &.w-100 {
    padding: 5px;
  }
}
.custom-row {
  @media screen and (min-width: 768) {
    margin-top: 20px !important;
  }
}

@media (min-width: 280px) and (max-width: 768px) {
  .write_review {
    img {
      margin-top: 18px;
    }
    h2 {
      font-size: 1.6rem;
    }
  }
  .socile_link {
    justify-content: center;
  }
  .ui.search .ui.input input.prompt {
    padding-left: 3.5em !important;
  }
  .footer {
    .logo {
      width: 9rem !important;
    }
  }
  .store_icons.d-flex {
    display: block !important;
    img {
      margin-bottom: 10px;
    }
  }
  .ui.rating {
    display: inline-block;
  }
}

// .react-google-flight-datepicker .day {
//   height: 25px !important;
// }
// .react-google-flight-datepicker .calendar-wrapper {
//   min-height: initial !important;
//   height: initial !important;
// }
// .react-google-flight-datepicker .dialog-date-picker {
//   width: 100% !important;
// }

@media (min-width: 768px) and (max-width: 1025px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 880px !important;
  }
  .company__logo {
    width: 8rem;
    height: 8rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding: 0px;
    }
  }
  .company_log_col.col-md-2 {
    padding: 0;
    flex: 0 0 12.666667%;
    max-width: 12.666667%;
  }
  .store_icons.d-flex {
    display: block !important;
    img {
      margin-bottom: 10px;
    }
  }
}
@media (min-width: 750px) and (max-width: 805px) {
  .company_details {
    .ui.card {
      .row {
        .col-md-8,
        .col-md-4 {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
  .companysocial_social {
    padding: 25px;
  }
  .company_nav____ {
    .ui.primary.button {
      margin-top: 10px;
    }
  }
}
.certificates_ {
  padding: 20px;
}
// @media screen and (min-width: 1025px) {
//   .col-md-6 {
//     max-width: 50% !important;
//   }
// }

.ui.fluid.dropdown {
  height: 40px !important;
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  // width: 127px;
}

.ui.search.dropdown > .text {
  vertical-align: middle;
}

@media screen and (min-width: 961px) {
  .dropDownCustomGrid {
    width: 19.2%;
  }
  .app[dir="ltr"] .dropDownCustomGrid {
    margin-right: 8px;
  }
  .app[dir="rtl"] .dropDownCustomGrid {
    margin-left: 8px;
  }
}
@media screen and (min-width: 720px) and (max-width: 960px) {
  .dropDownCustomGrid {
    width: 33.333333%;
  }
}
@media screen and (min-width: 540px) and (max-width: 719px) {
  .dropDownCustomGrid {
    width: 50%;
  }
}
@media screen and (max-width: 539px) {
  .dropDownCustomGrid {
    width: 100%;
  }
}

@media screen and (max-width: 594px) {
  .earn-divs-flex {
    display: block !important;
    // text-align: center !important;
  }
  .text-salary-earn {
    width: initial !important;
  }
  .three-divs-flex {
    text-align: center !important;
  }
  .three-divs-flex > .text-salary {
    margin: 20px 2rem;
  }
}
.styled_range .rangeslider .rangeslider__handle {
  background-color: #0a3962 !important;
}
