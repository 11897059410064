ul li a.nav-link {
  font-size: 1.2rem;
}

.navbar {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.45) !important;
}

.nav_white {
  background: #ffffff;
}

.nav-link:hover {
  color: #0a3962 !important;
}
.user_avatar {
  height: 30px;
  width: 30px;
  background: #0a3962;
  border-radius: 50%;
  color: #a0bffd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.nav-link.active {
  color: #0089ef !important;
}

.nav-link:hover {
  color: #0089ef !important;
}

@media (max-width: 1000px) {
  .navbar-nav .nav-link,
  .nav-item {
    text-align: center;
  }
}

.user_avatar_image {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.show-search {
  display: inline-flex;
  align-items: center;
  margin: 0 20px;
  cursor: pointer;
}
@media (max-width: 992px) {
  .show-search {
    display: none;
  }
}
