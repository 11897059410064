.footer {
  background: url(../assets/images/footer_bg.svg) #f6f6f6;
  .logo {
    width: 12rem;
  }
  .col_title {
    color: #0a3962;
    font-size: 1.1rem;
    @media screen and (max-width: 746px) {
      margin-top: 2rem;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    font-size: 1.1rem;
    li {
      margin: 0;
      margin-top: 0.5rem;
      a {
        transition: all .2s ease-in-out;
        color: #0a3962 !important;
        &:hover{
          color: #0894ff!important;
        }
      }
    }
  }
}

.get_in_touch {
  color: #0a3962;
  font-size: 1.3rem;
}

.socile_link {
  display: flex;
  .icons {
    margin: 3px;
  }
}

.copy_right {
  background: #f4f4f4;
  text-align: center;
}
