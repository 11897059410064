.line_wrapper {
  position: relative;
  width: 100%;
  height: 34rem;

  .line {
    position: absolute;
    left: 49%;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #ccc;
    z-index: 1;
  }

  .wordwrapper {
    text-align: center;
    height: 12px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -12px;
    z-index: 2;
  }

  .word {
    color: #0a3962;
    text-transform: capitalize;
    letter-spacing: 1px;
    padding: 3px;
    font-size: 1.2rem;
    background: #fff;
  }
}

.sign_line {
  @media screen and (max-width: 799px) {
    display: none;
  }
}

.toggle_show_password {
  position: absolute;
  top: 14px;
  &.ar {
    left: 10px;
  }
  &.en {
    right: 10px;
  }
}
