$main-color: #0a3962;
$btn_blue_colo: #0089ef;
$dark_black: #474747;
$light_black: #707070;
$black: #1f1f1f;
$gray_color: #f6f6f6;
$gray_darken: #cccccc;
$white_color: #ffffff;

@import "./styles/login.scss";
@import "./styles/register.scss";

@font-face {
  font-family: Arial, Cairo;
  src: url(./assets/fonts/arial.ttf);
}
html {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
}
body {
  // height: 20000px;
  // font-family: Arial;
  // font-family: "El Messiri', sans-serif" !important;
}

.app[dir="rtl"] {
  h1,
  h2,
  h3,
  p,
  span {
    font-family: "Cairo", sans-serif;
  }
}
.text_blue {
  color: $btn_blue_colo;
}
.text_ar {
  font-family: "Cairo", sans-serif !important;
}
.main_color {
  color: $main-color;
}
.blue_text {
  color: $btn_blue_colo;
}
.secounry_c {
  color: #474747;
}
a:not(.btn-link) {
  color: $black !important;
}

.text_success {
  color: #5fa77e;
}

.text_muted {
  color: #848484;
}
.ui.rating .active.icon {
  color: #ffdc64;
}

.ui.pagination.menu {
  .active.item {
    background-color: $main-color;
    color: white !important;
    outline: none;
    &:focus {
      outline: none;
    }
  }
}

.adv_img {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

.ui.primary.button,
.ui.primary.buttons .button {
  background-color: $btn_blue_colo;
}
.card {
  border: none !important;
  border-radius: 10px !important;
}
.navbar-toggler {
  border-color: #0000 !important;
}

@media screen and (max-width: 974px) {
  .navbar.navbar-expand-lg.fixed-top {
    background: #ffffff;
  }
}

.ui.search .ui.input {
  @media screen and (max-width: 678px) {
    width: 26rem !important;
  }
}
.results.transition.visible {
  max-height: 21rem;
  overflow-x: hidden;
  overflow-y: scroll;
}
.ui.progress {
  // background: white;
}

a.btn-link {
  color: #0089ef !important;
}

// process
progress {
  border: 0;
  background: #eee;
  border-radius: 100px;
}

.en {
  progress::-moz-progress-bar {
    border-radius: 100px;
    background: linear-gradient(
      to right,
      rgb(0, 139, 239),
      rgba(33, 177, 89, 0.1)
    );
  }
  progress::-webkit-progress-value {
    border-radius: 100px;
    background: linear-gradient(
      to right,
      rgb(0, 139, 239),
      rgba(20, 63, 204, 0.199)
    );
  }
}

.ar {
  progress::-moz-progress-bar {
    border-radius: 100px;
    background: linear-gradient(
      to left,
      rgb(0, 139, 239),
      rgba(33, 177, 89, 0.1)
    );
  }
  progress::-webkit-progress-value {
    border-radius: 100px;
    background: linear-gradient(
      to left,
      rgb(0, 139, 239),
      rgba(20, 63, 204, 0.199)
    );
  }
}

progress::-webkit-progress-bar {
  background: #eee;
  border-radius: 100px;
}

.rate_bu {
  position: absolute;
  left: 45%;
  bottom: -9px;
}
.cls-1 {
  fill: #2185d0 !important;
}

// .form_trasform {
//   transform: translateX(-12px);
// }
.ui.menu .item:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  background: rgba(34, 36, 38, 0.1);
}
