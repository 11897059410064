.custom-select {
  border-radius: 10px !important;
  outline: none !important;
  border: none !important;
  padding: 0 !important;
}
.custom-select > div {
  width: 100%;
  border-radius: 10px !important;
  outline: none !important;
  opacity: 1;
  color: #474747 !important;
  background: #fcfcfc 0% 0% no-repeat padding-box !important;
}
.custom-select > div:focus {
  border: 2px solid #0089ef !important;
  background: white !important;
}
