.custome_dropdown {
  &.ar {
    .dropdown {
      text-align: right;
      padding: 0.8rem;
      font-family: "Cairo", sans-serif !important;
      .message {
        text-align: right;
      }
    }
    .ui.selection.dropdown > .delete.icon,
    .ui.selection.dropdown > .dropdown.icon,
    .ui.selection.dropdown > .search.icon {
      left: 1rem;
      right: unset;
    }
    .item {
      text-align: right !important;
    }
  }
  // .en {
  .dropdown.icon {
    float: right;
    top: 5px;
  }
  // }
  .visible.menu.transition {
    width: 100%;
  }
}
.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  // width: 127px;
  height: 30px;
}
