.search_input.text-right > div > input {
  text-align: right;
}
.search_input.text-left > div > input {
  text-align: left;
}

.search_input {
  .results .result .content {
    .title {
      font-family: "Cairo", sans-serif;
    }
  }

  .message.empty {
    text-align: right;
    .header {
      font-family: "Cairo", sans-serif !important;
      cursor: pointer;
    }
  }
}
.ui.search > .results {
  margin-top: -1px !important ;
}

.red {
  color: red;
}

@media screen and (max-width: 400px) {
  .input input {
    font-size: 13px !important;
  }
}
